
import {
  defineComponent,
  reactive,
  toRefs,
  inject,
  nextTick,
  watch,
  ref,
  getCurrentInstance,
  onMounted,
  onBeforeUnmount
} from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n-composable";
import type { FroalaEditor as EditorType, FroalaOptions } from "froala-editor";

import speechRecognition from "@/components/speechRecognition.vue";
import speechSynthesisBtn from "@/components/speechSynthesisBtn.vue";

import FroalaEditor, { defaultConfig } from "@/plugins/froala";
import { useRoute } from "@/router";
import { languageText } from "@/i18n";
import { useStore } from "@/store/index";

import { logger } from "@/helpers";
import messaging from "@/plugins/messaging";
import {
  assessmentItemIdKey,
  interactionInitializedKey
} from "@/injectionKeys";

import { mdiCloudOffOutline, mdiSync, mdiCloudCheck, mdiDelete } from "@mdi/js";

const spellCheckLanguages = [
  {
    value: "en_US",
    text: languageText({ languageCode: "en", regionCode: "us" })
  },
  {
    value: "sv_SE",
    text: languageText({ languageCode: "sv" })
  },
  {
    value: "nn_NO",
    text: languageText({ languageCode: "no", regionCode: "nn" })
  },
  {
    value: "nb_NO",
    text: languageText({ languageCode: "no", regionCode: "nb" })
  },
  {
    value: "bg_BG",
    text: languageText({ languageCode: "bg" })
  },
  {
    value: "nl_NL",
    text: languageText({ languageCode: "nl" })
  },
  {
    value: "da_DK",
    text: languageText({ languageCode: "da" })
  },
  {
    value: "es_ES",
    text: languageText({ languageCode: "es" })
  },
  {
    value: "pt_PT",
    text: languageText({ languageCode: "pt" })
  },
  {
    value: "pt_BR",
    text: languageText({ languageCode: "pt", regionCode: "br" })
  },
  {
    value: "de_DE",
    text: languageText({ languageCode: "de" })
  },
  {
    value: "en_GB",
    text: languageText({ languageCode: "en", regionCode: "gb" })
  },
  {
    value: "en_CA",
    text: languageText({ languageCode: "en", regionCode: "ca" })
  },
  {
    value: "fi_FI",
    text: languageText({ languageCode: "fi" })
  },
  {
    value: "fr_FR",
    text: languageText({ languageCode: "fr" })
  },
  {
    value: "fr_CA",
    text: languageText({ languageCode: "fr", regionCode: "ca" })
  },
  {
    value: "el_GR",
    text: languageText({ languageCode: "el" })
  },
  {
    value: "it_IT",
    text: languageText({ languageCode: "it" })
  },
  {
    value: "ru_RU",
    text: languageText({ languageCode: "ru" })
  }
];
const icons = {
  mdiCloudOffOutline,
  mdiSync,
  mdiCloudCheck,
  mdiDelete
};

export default defineComponent({
  name: "extendedTextInteraction",

  components: {
    speechRecognition
  },

  props: {
    value: String,
    fullscreen: { type: Boolean, default: false },
    maxWords: Number,
    readOnly: { type: Boolean, default: false }
  },

  emits: ["input"],

  setup(props, { emit }) {
    const store = useStore();
    const internalInstance = getCurrentInstance();
    const route = useRoute();
    const root = ref<HTMLDivElement | null>(null);
    const editorElement = ref<HTMLDivElement | null>(null);
    const speechRecognitionElement = ref<InstanceType<
      typeof speechRecognition
    > | null>(null);
    const i18n = useI18n();

    let editor: FroalaEditor.FroalaEditor | null = null;

    const data = reactive({
      numBytes: 0,
      numWords: 0,
      numChars: 0,

      wordLimitWarning: false,

      wordCountDialog: false,

      speechSynthesisDialog: false,
      speechRecognize: false,

      spellCheckLanguageDialog: false,
      ignoredWords: [] as string[],
      selectedSpellCheckLanguage: "NOT_SET" as string | undefined,

      inputValue: ""
    });

    const assessmentItemId = inject(assessmentItemIdKey);
    const interactionInitialized = inject(interactionInitializedKey);

    const {
      speechSynthesis,
      speechRecognitionLanguage,
      spellCheckLanguage,
      allowPrint,
      moduleType
    } = storeToRefs(store);

    function updateReadonly(v: boolean) {
      if (editor && !editor.destroying) {
        if (v) {
          editor.edit.off();
        } else {
          editor.edit.on();
        }
      }
    }

    function froalaLang() {
      switch (i18n.locale.value) {
        case "pt-PT":
          return "pt_pt";

        case "es-ES":
          return "es";

        case "bg-BG":
          return "bg_bg";

        case "fr-FR":
          return "fr";

        case "et-ET":
          return "et";

        case "cy-GB":
          return "gb";

        default:
          return i18n.locale.value;
      }
    }

    async function editorInitialized() {
      if (!editor) return;
      editor.el.addEventListener("spellCheckSettings", () => {
        data.ignoredWords = editor!.spellChecker.getIgnoredWords();
        data.spellCheckLanguageDialog = true;
      });

      editor.el.addEventListener("spellCheckContentChanged", () => {
        emit("input", editor!.html.get());
        editor!.undo.saveStep();
      });

      editor.el.addEventListener("versionHistory", versionHistoryBtn);

      // ts doesn't recognize this kind of component use
      editor.el.addEventListener("speechSynthesis", speechSynthesisBtn as any);

      editor.el.addEventListener("speechRecognition", speechRecognitionBtn);

      editor.el.addEventListener("print", () => {
        messaging.syncMessage("PRINT");
      });

      editor.el.addEventListener("wordCounter", () => {
        data.wordCountDialog = true;
      });

      editor.html.set(props.value || "");

      (
        root.value!.querySelector(".fr-element") as HTMLElement
      ).style.visibility = "visible";

      updateReadonly(props.readOnly);
      editor.undo.saveStep();
      interactionInitialized?.();
    }

    function editorContentChanged(noEmit = false) {
      if (!editor) return;
      data.numBytes = editor.el.innerHTML.length;
      data.numChars = editor.el.innerText.replace(/\s+/g, "").length;
      data.numWords =
        data.numChars === 0
          ? 0
          : editor.el.innerText.trim().split(/\s+/).length;
      if (moduleType.value === "qti_editor") {
        store.setEditorMetadata({
          word_count: data.numWords,
          char_count: data.numChars
        });
      }

      if (!noEmit) {
        emit("input", editor.html.get());
      }
    }

    function versionHistoryBtn(event: Event) {
      // stop bubbling upwards
      event.stopPropagation();
      if (document.activeElement) {
        try {
          // try to blur so editorContentChanged can be triggered before we
          // set read only.
          (document.activeElement as HTMLElement).blur();
        } catch (error) {
          logger.warn(error);
        }
      }

      store.setRevisionDialog(true);
    }

    function speechRecognitionBtn(event: any) {
      // stop bubbling upwards
      event.stopPropagation();

      if (event && event.detail === "opts") {
        speechRecognitionElement.value!.showSettings();
      } else if (event && event.detail === "speak") {
        speechRecognitionElement.value!.recognitionStart(editor);
      }
    }

    function removeIgnoredWord(word: string) {
      if (!editor) return;
      data.ignoredWords.splice(data.ignoredWords.indexOf(word), 1);
      editor.spellChecker.removeIgnoredWord(word);
    }

    function rescaleEditorHeight() {
      if (!editor) return;
      const top = (root.value!.querySelector(".fr-toolbar") as HTMLElement)
        .offsetTop;
      const offset = window.innerHeight - 65 - top;
      editor.opts.heightMin = Math.max(offset, 120);
      editor.size.refresh();
    }

    watch(
      () => props.value,
      v => {
        if (!editor) return;
        if (JSON.stringify(v) == JSON.stringify(editor.html.get())) {
          return;
        }
        editor.html.set(v ?? "");
        editorContentChanged(true);
        editor.undo.reset();
        editor.undo.saveStep();
        if (editor.spellChecker) {
          editor.spellChecker.spellCheck();
        }
      }
    );

    watch(
      () => props.readOnly,
      v => {
        updateReadonly(v);
      }
    );

    watch(
      () => data.selectedSpellCheckLanguage,
      (v, oldValue) => {
        if (oldValue === "NOT_SET") return;

        if (editor && editor.spellChecker) {
          editor.spellChecker.changeLanguage(v);
        }
      }
    );

    watch(
      () => data.numWords,
      (n1, n2) => {
        if (!props.maxWords || !n1) return;

        if ((!n2 || n2 <= props.maxWords) && n1 > props.maxWords) {
          data.wordLimitWarning = true;
        }
      }
    );

    watch(
      () => route.query,
      to => {
        if (to.itemId === assessmentItemId) {
          if (editor) {
            nextTick(() => {
              if (editor!.spellChecker.isEnabled()) {
                editor!.spellChecker.spellCheck();
              }
              editorContentChanged();
            });
          }
        }
      }
    );

    data.selectedSpellCheckLanguage = spellCheckLanguage.value;

    onBeforeUnmount(() => {
      if (editor) {
        editor.destroy();
        editor = null;
      }
    });

    const config: Partial<FroalaOptions> = {
      ...defaultConfig(),
      language: froalaLang(),
      spellCheckLanguage: spellCheckLanguage.value,

      events: {
        // eslint-disable-next-line no-unused-vars
        initialized(this: EditorType) {
          nextTick(editorInitialized);
        },
        "commands.after": function (cmd: string) {
          if (
            props.fullscreen &&
            internalInstance?.proxy.$root.$vuetify.breakpoint.smAndDown &&
            ["moreMisc", "moreParagraph", "moreRich", "moreText"].includes(cmd)
          ) {
            rescaleEditorHeight();
          }
        },
        contentChanged() {
          editorContentChanged();
        }
      }
    };
    const miscButtons = [];
    if (
      spellCheckLanguage.value ||
      speechSynthesis.value ||
      speechRecognitionLanguage.value
    ) {
      if (speechSynthesis.value) {
        miscButtons.push("speechSynthesis");
      }
      if (
        speechRecognitionLanguage.value &&
        (window.SpeechRecognition || window.webkitSpeechRecognition) &&
        /Chrom[^/]+\/\d+/.test(window.navigator.userAgent)
      ) {
        miscButtons.push("speechRecognition");
      }
      if (spellCheckLanguage.value) {
        miscButtons.push("spellChecker");
      }
    }

    if (props.fullscreen) {
      miscButtons.push("zoom");
      config.toolbarButtonsSM!.moreParagraph!.buttonsVisible = 3;
      config.toolbarButtons!.moreParagraph!.buttonsVisible = 3;
      config.toolbarButtonsSM!.moreText!.buttonsVisible = 3;
      config.toolbarButtons!.moreText!.buttonsVisible = 3;
      config.toolbarButtonsSM!.moreMisc!.buttonsVisible = 6;
      config.toolbarButtons!.moreMisc!.buttonsVisible = 6;
      config.heightMin = Math.max(window.innerHeight - 65, 120);
    } else {
      config.heightMin = 120;
    }
    if (allowPrint.value) {
      miscButtons.push("print");
    }
    config.toolbarButtons!.moreMisc!.buttons.splice(3, 0, ...miscButtons);
    config.toolbarButtonsSM!.moreMisc!.buttons.splice(3, 0, ...miscButtons);
    config.toolbarButtonsXS!.moreMisc!.buttons.splice(3, 0, ...miscButtons);

    onMounted(() => {
      editor = new FroalaEditor(editorElement.value, config);

      window.addEventListener("resize", () => {
        if (!editor) return;
        editorContentChanged();
        if (
          props.fullscreen &&
          // TODO surely there's a better way
          internalInstance?.proxy.$root.$vuetify.breakpoint.smAndDown
        ) {
          rescaleEditorHeight();
        }

        if (editor.spellChecker.isEnabled()) {
          editor.spellChecker.spellCheck();
        }
      });
    });

    return {
      root,
      speechRecognitionElement,
      editorElement,
      spellCheckLanguages,
      icons,
      speechRecognitionLanguage,
      spellCheckLanguage,
      removeIgnoredWord,
      ...toRefs(data)
    };
  }
});
