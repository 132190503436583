import XRegExp from "xregexp";
import axios from "axios";
import { logger } from "@/helpers";

import { mdiSpellcheck } from "@mdi/js";
import i18n from "@/i18n";
import { debounce } from "@/helpers";
import FroalaEditor from "froala-editor";

export class Dictionary {
  #minWordLength = 2;
  #language = "en_US";

  #words: { [id: string]: any } = {};

  ignoredWords: { [id: string]: null } = {};

  /**
   * @param {string} languageTag the dictionary language
   */
  constructor(languageTag: string) {
    this.#language = languageTag;
  }

  destroy() {
    this.ignoredWords = {};
    this.#words = {};
  }

  /**
   * Prepare word with some formatting limits
   */
  _wordPrepare(word: string) {
    return word.slice(0, 128);
  }

  ignoreWord(word: string) {
    this.ignoredWords[this._wordPrepare(word)] = null;
  }

  removeIgnoredWord(word: string) {
    delete this.ignoredWords[this._wordPrepare(word)];
  }

  async _fetchWords(params: any) {
    params.set("t", this.#language);
    if (!this.#words) return;

    try {
      const { data } = await axios.post(
        process.env.VUE_APP_SPELLCHECK_URL!,
        undefined,
        { params: params }
      );

      const words = Object.keys(data);
      for (let i = 0; i < words.length; i++) {
        const word = words[i];
        if (data[word] === null) {
          // could not check word
          delete this.#words[word];
          continue;
        }

        this.#words[word] = data[word];
      }
    } catch (err) {
      logger.warn(err);
      params.getAll("w").forEach((word: string) => {
        delete this.#words![word];
      });
    }
  }

  /**
   * Load word from remote dictionary
   *
   * @param {Array<String>} words
   * @returns {Promise} a promise that resolves when the word is fetched from our remote dicitonary
   */
  async load(words: string[]): Promise<any> {
    let done: (value?: unknown) => void;

    // create a promise that will be resolved once all words have been fetched
    const p = new Promise(resolve => {
      // expose our resolve callback to outside the promise scope
      done = resolve;
    });

    const promises = [];

    const params = new URLSearchParams();

    for (const entry of words) {
      const word = this._wordPrepare(entry);
      if (typeof this.#words[word] === "object") {
        // if the word is an object it is either in flight or the results
        // have been fetched
        const obj = this.#words[word];
        if (typeof obj.then === "function" && obj !== p) {
          // the object is a promise that was not created in the current
          // this.load call, lets await it at the end.
          promises.push(obj);
        }

        continue;
      }

      if (this.ignoredWords[word] !== undefined) {
        continue;
      }

      if (word.length < this.#minWordLength) {
        continue;
      }

      // add our promise to the word list so other this.load calls can
      // await the results.
      this.#words[word] = p;

      params.append("w", word);
    }

    if (params.has("w")) {
      promises.push(
        this._fetchWords(params).finally(() => {
          done();
        })
      );
    }

    return Promise.all(promises);
  }

  /**
   * Check if word is found in dictionary
   *
   * @param {string} word
   * @returns {boolean} a bool if word was found in our dictionary
   */
  found(word: string): boolean {
    const w = this._wordPrepare(word);
    if (w.length <= this.#minWordLength || this.ignoredWords[w] !== undefined) {
      return true;
    }

    if (this.#words[w] === undefined) {
      // network is down and word could not be spell checked.
      return true;
    }

    return this.#words[w] && this.#words[w].f;
  }

  /**
   * Get spelling suggestions for our word
   *
   * @param {string} word   Fzz
   * @returns {string[]} a list of suggestions
   */
  suggest(word: string): string[] {
    if (!this.#words) return [];
    const w = this._wordPrepare(word);
    if (this.#words[w] && this.#words[w].s) {
      return this.#words[w].s;
    }

    return [];
  }
}

export class SpellChecker {
  #editor;
  #dictionary;

  #enabled = true;

  #contextMenu;
  #contextMenuList;
  #contextMenuElement: any;

  #lastPromise?: Promise<any>;

  #outerRect: any;

  #container: Element;
  #elements: { [id: string]: any } = {};

  #misspelledWords: string[] = [];

  spellCheck: any;

  constructor(editor: FroalaEditor.FroalaEditor, languageTag: string) {
    this.#editor = editor;
    this.#dictionary = new Dictionary(languageTag);

    this.#container = document.createElement("div");
    this.#container.classList.add("spell-check-container");
    this.#editor.el.parentElement!.insertBefore(
      this.#container,
      this.#editor.el
    );

    this.#contextMenu = document.createElement("div");
    this.#contextMenu.classList.add("spell-check-contextmenu");
    this.#editor.el.parentElement!.insertBefore(
      this.#contextMenu,
      this.#editor.el
    );

    this.#contextMenuList = document.createElement("ul");
    this.#contextMenu.appendChild(this.#contextMenuList);

    this.#editor.el.style.zIndex = "10";

    this.spellCheck = debounce(() => {
      this._spellCheck();
    }, 400);

    this.#editor.el.addEventListener("mousemove", (event: MouseEvent) => {
      this.updateOuterRect();
      this.mouseMove(event.clientX, event.clientY);
    });

    this.#editor.events.on("keydown", () => {
      this.spellCheck.call();
    });

    this.#editor.events.on("keyup", () => {
      this.spellCheck.call();
    });

    this.#editor.events.on("html.set", () => {
      this.spellCheck.call();
    });

    this.#editor.events.on("contentChanged", () => {
      this.spellCheck.call();
    });

    this.#editor.events.on("commands.after", () => {
      this.spellCheck.call();
    });
  }

  updateOuterRect() {
    const rect = this.#editor.el.getBoundingClientRect();
    const scale = rect.width / this.#editor.el.offsetWidth;

    this.#outerRect = {
      scale,
      top: rect.top,
      left: rect.left,
      width: rect.width,
      height: rect.height
    };
  }

  mouseMove(x: number, y: number) {
    let mouseoverElement;
    let mouseoverWord = "";

    const scrollX = (x - this.#outerRect.left) / this.#outerRect.scale;
    const scrollY = (y - this.#outerRect.top) / this.#outerRect.scale;

    const words = Object.keys(this.#elements);
    wordLoop: for (let i = 0; i < words.length; i++) {
      const word = words[i];
      for (let j = 0; j < this.#elements[word].length; j++) {
        if (
          this.#elements[word][j].top < scrollY &&
          this.#elements[word][j].bottom > scrollY &&
          this.#elements[word][j].left < scrollX &&
          this.#elements[word][j].right > scrollX
        ) {
          mouseoverElement = this.#elements[word][j];
          mouseoverWord = word;

          if (this.#contextMenuElement !== mouseoverElement) {
            const span = this.#container.querySelector(".spell-check-hover");
            if (span) {
              span.classList.remove("spell-check-hover");
            }
          }

          const spans = this.#container.querySelectorAll(
            `[data-word="${word}"]`
          );
          spans[j].classList.add("spell-check-hover");

          break wordLoop;
        }
      }
    }

    if (!mouseoverElement) {
      this.#contextMenu.style.display = "none";

      if (this.#contextMenuElement) {
        const span = this.#container.querySelector(".spell-check-hover");
        if (span) {
          span.classList.remove("spell-check-hover");
        }

        this.#contextMenuElement = null;
      }
    } else {
      if (this.#contextMenuElement !== mouseoverElement) {
        // word changed, remove all children
        while (this.#contextMenuList.lastChild) {
          this.#contextMenuList.removeChild(this.#contextMenuList.lastChild);
        }

        this.#contextMenuElement = mouseoverElement;

        this.#contextMenu.style.left = `${mouseoverElement.left}px`;
        this.#contextMenu.style.top = `${mouseoverElement.bottom}px`;

        const suggestions = this.#dictionary.suggest(mouseoverWord);
        for (let i = 0; i < Math.min(suggestions.length, 8); i++) {
          const listItem = document.createElement("li");
          this.#contextMenuList.appendChild(listItem);

          listItem.innerHTML = suggestions[i];

          listItem.addEventListener("click", () => {
            this.#contextMenu.style.display = "none";

            const range = this.#contextMenuElement.range;
            range.deleteContents();
            range.insertNode(document.createTextNode(suggestions[i]));

            this.spellCheck.call();
            this.#editor.el.dispatchEvent(
              new CustomEvent("spellCheckContentChanged")
            );
          });
        }

        if (suggestions.length === 0) {
          const noSuggestionsItem = document.createElement("li");
          this.#contextMenuList.appendChild(noSuggestionsItem);
          noSuggestionsItem.innerText = String(i18n.t("no_suggestions"));
          noSuggestionsItem.classList.add("no-suggestion");
        }

        this.#contextMenuList.appendChild(document.createElement("hr"));

        const ignoreItem = document.createElement("li");
        this.#contextMenuList.appendChild(ignoreItem);
        ignoreItem.innerText = String(i18n.t("ignore"));
        ignoreItem.addEventListener("click", () => {
          this.#contextMenu.style.display = "none";
          this.#dictionary.ignoreWord(mouseoverWord);
          this.spellCheck.call();
        });

        const optionsItem = document.createElement("li");
        this.#contextMenuList.appendChild(optionsItem);
        optionsItem.innerText = String(i18n.t("settings"));
        optionsItem.addEventListener("click", () => {
          this.#contextMenu.style.display = "none";
          this.#editor.el.dispatchEvent(new CustomEvent("spellCheckSettings"));
        });
      }

      this.#contextMenu.style.display = "block";
    }
  }

  async _spellCheck() {
    if (!this.#enabled) return;

    // textContent doesn't split "<p>Hello</p><p>World</p>" but instead
    // returns "HelloWorld" while innerText returns "Hello\nWorld"
    const txt = this.#editor.el.innerText;

    const newlineIndices: number[] = [];

    const whitespaceRegex = new RegExp(/[\r\n\v\f\t]/g);

    let m = null;
    while ((m = whitespaceRegex.exec(txt)) !== null) {
      newlineIndices.push(m.index);
    }

    /**
     * Take an index based on text that contains newline characters
     * and return what the index would be in a text with all newline
     * characters stripped.
     *
     * @param {Number} newlineIndex the index in text with newline
     *
     * @returns {Number} what the index would be in a text with newlines stripped.
     */
    const indexWithoutWhitespace = (newlineIndex: number) => {
      if (newlineIndices.length === 0) {
        return newlineIndex;
      }

      let index = newlineIndex;
      let i = 0;

      // for each newline before the index, lower the index count by 1
      // since we don't want to count newlines.
      while (newlineIndices[i] < newlineIndex) {
        index--;
        i++;
      }

      return index;
    };

    const words: { [id: string]: number[] } = {};

    let startPos = 0;
    m = null;
    while ((m = XRegExp.exec(txt, XRegExp("\\pL+", "gm"), startPos)) !== null) {
      const word = m[0];
      const index = m.index;
      if (words[word] === undefined) {
        words[word] = [];
      }

      words[word].push(indexWithoutWhitespace(index));
      startPos = index + word.length;
    }

    const promise = this.#dictionary.load(Object.keys(words));
    this.#lastPromise = promise;
    await promise;

    if (promise !== this.#lastPromise) {
      // newer check triggered
      return;
    }

    // filter out words that are no longer misspelled or ignored
    const correctedWords = this.#misspelledWords.filter(
      w => words[w] === undefined || this.#dictionary.found(w)
    );

    // update our misspelled words
    this.#misspelledWords = Object.keys(words).filter(
      word => !this.#dictionary.found(word)
    );

    this.changeDOM(words, correctedWords);
  }

  changeDOM(words: { [id: string]: number[] }, correctedWords: string[]) {
    const container = this.#container.cloneNode(true) as Element;

    // remove highlights from corrected words
    correctedWords.forEach((word: string) => {
      if (this.#elements[word] === undefined) {
        return;
      }

      const spans = container.querySelectorAll(`[data-word="${word}"]`);
      for (let i = 0; i < spans.length; i++) {
        container.removeChild(spans[i]);
      }

      this.#elements[word] = [];
    });

    this.updateOuterRect();
    if (
      !this.#outerRect ||
      this.#outerRect.width === 0 ||
      this.#outerRect.height === 0
    ) {
      return;
    }

    for (const word of this.#misspelledWords) {
      if (words[word] === undefined) continue;
      this.repositionWordHighlights(container, word, words[word]);
    }

    this.#container.parentElement?.replaceChild(container, this.#container);
    this.#container = container;
  }

  repositionWordHighlights(
    container: Element,
    word: string,
    indices: number[]
  ) {
    if (this.#elements[word] === undefined) {
      this.#elements[word] = [];
    }

    const spans = container.querySelectorAll(`[data-word="${word}"]`);

    for (let i = 0; i < indices.length; i++) {
      let element = this.#elements[word][i];
      let span = spans[i] as HTMLSpanElement;

      if (span === undefined) {
        span = document.createElement("span");
        span.classList.add("spell-check");
        span.setAttribute("data-word", word);
        container.appendChild(span);
      }

      const range = this.createRange(this.#editor.el, {
        startIndex: indices[i],
        endIndex: indices[i] + word.length,
        currentIndex: 0
      });

      const rects = range.getClientRects();
      const rect = rects[rects.length - 1];

      const top = (rect.top - this.#outerRect.top) / this.#outerRect.scale;
      const right = (rect.right - this.#outerRect.left) / this.#outerRect.scale;
      const bottom =
        (rect.bottom - this.#outerRect.top) / this.#outerRect.scale;
      const left = (rect.left - this.#outerRect.left) / this.#outerRect.scale;

      let positionChanged = false;

      if (element === undefined) {
        element = {
          range,
          top: top,
          right: right,
          bottom: bottom,
          left: left
        };
        this.#elements[word][i] = element;

        positionChanged = true;
      } else {
        element.range = range;

        if (
          element.top != top ||
          element.right !== right ||
          element.bottom !== bottom ||
          element.left !== left
        ) {
          element.top = top;
          element.right = right;
          element.bottom = bottom;
          element.left = left;

          positionChanged = true;
        }
      }

      if (positionChanged) {
        span.style.top = `${top}px`;
        span.style.left = `${left}px`;

        span.style.width = `${right - left}px`;
        span.style.height = `${bottom - top}px`;
      }
    }

    if (indices.length < spans.length) {
      // remove unnecessary highlights
      for (let j = indices.length; j < spans.length; j++) {
        container.removeChild(spans[j]);
      }

      this.#elements[word] = this.#elements[word].slice(0, indices.length);
    }
  }

  createRange(
    node: Node,
    offsets: {
      startIndex: number;
      endIndex: number;
      currentIndex: number;
    },
    range?: Range
  ): Range {
    if (!range) {
      range = document.createRange();
    }

    if (offsets.currentIndex === offsets.startIndex) {
      range.setStart(node, 0);
    }

    if (offsets.currentIndex === offsets.endIndex) {
      range.setEnd(node, 0);
    } else if (node && offsets.currentIndex < offsets.endIndex) {
      if (node.nodeType === Node.TEXT_NODE) {
        const textLength = node.textContent?.length ?? 0;

        if (
          offsets.currentIndex < offsets.startIndex &&
          offsets.currentIndex + textLength >= offsets.startIndex
        ) {
          range.setStart(node, offsets.startIndex - offsets.currentIndex);
        }

        if (
          offsets.currentIndex < offsets.endIndex &&
          offsets.currentIndex + textLength >= offsets.endIndex
        ) {
          range.setEnd(node, offsets.endIndex - offsets.currentIndex);
        }

        offsets.currentIndex += textLength;
      } else {
        for (let i = 0; i < node.childNodes.length; i++) {
          range = this.createRange(node.childNodes[i], offsets, range);
          if (offsets.currentIndex >= offsets.endIndex) {
            break;
          }
        }
      }
    }

    return range;
  }

  enable() {
    // check will be triggered by commands.after event
    this.#enabled = true;
  }

  disable() {
    // check will be triggered by commands.after event
    this.#enabled = false;

    this.#misspelledWords = [];
    this.#elements = {};

    const container = this.#container.cloneNode(false);
    this.#container.parentElement?.replaceChild(container, this.#container);
    this.#container = container as Element;
  }

  toggleEnabled() {
    if (this.#enabled) {
      this.disable();
    } else {
      this.enable();
    }
  }

  isEnabled() {
    return this.#enabled;
  }

  changeLanguage(language: string) {
    this.#dictionary.destroy();
    this.#dictionary = new Dictionary(language);
    this.spellCheck.call();
  }

  removeIgnoredWord(word: string) {
    this.#dictionary.removeIgnoredWord(word);
    this.spellCheck.call();
  }

  getIgnoredWords() {
    return Object.keys(this.#dictionary.ignoredWords);
  }
}

export const plugin = function () {
  // Add an option for your plugin.
  FroalaEditor.DEFAULTS.spellCheckLanguage = "en_US";

  FroalaEditor.DefineIcon("spellChecker", {
    MDI: mdiSpellcheck,
    template: "material_design"
  });

  FroalaEditor.RegisterCommand("spellChecker", {
    title: String(i18n.t("spell_checker")),
    focus: true,
    undo: false,

    type: "dropdown",
    options: {
      toggle: `${i18n.t("activate")} / ${i18n.t("disable")}`,
      settings: i18n.t("settings")
    },

    refreshAfterCallback: true,

    callback: function (this: FroalaEditor.FroalaEditor, cmd, val) {
      if (val === "settings") {
        this.el.dispatchEvent(new CustomEvent("spellCheckSettings"));
      } else {
        this.spellChecker.toggleEnabled();
      }
    },

    refresh: function (this: FroalaEditor.FroalaEditor, $btn) {
      const enabled = this.spellChecker.isEnabled();
      const hasClass = $btn[0].classList.contains("light-blue--text");
      if (enabled && !hasClass) {
        $btn[0].classList.add("light-blue--text");
        $btn[0].classList.remove("grey--text");
      } else if (!enabled && hasClass) {
        $btn[0].classList.remove("light-blue--text");
        $btn[0].classList.add("grey--text");
      }
    }
  });

  // Define the plugin.
  // The editor parameter is the current instance.
  FroalaEditor.PLUGINS.spellChecker = function (editor) {
    let spellChecker: SpellChecker;

    function _init() {
      const language = editor.opts.spellCheckLanguage;
      if (language) {
        spellChecker = new SpellChecker(editor, language);
      }
    }

    return {
      _init: _init,
      changeLanguage(language: string) {
        if (!spellChecker) return;
        spellChecker.changeLanguage(language);
      },
      isEnabled() {
        if (!spellChecker) return false;
        return spellChecker.isEnabled();
      },
      toggleEnabled() {
        if (!spellChecker) return;
        spellChecker.toggleEnabled();
      },
      spellCheck() {
        if (!spellChecker) return;
        spellChecker.spellCheck.call();
      },
      getIgnoredWords() {
        if (!spellChecker) return [];
        return spellChecker.getIgnoredWords();
      },
      removeIgnoredWord(word: string) {
        if (!spellChecker) return;
        return spellChecker.removeIgnoredWord(word);
      }
    };
  };
};

export interface SpellCheckerPlugin {
  changeLanguage(language: string): void;
  isEnabled(): boolean;
  toggleEnabled(): void;
  spellCheck(): void;
  getIgnoredWords(): string[];
  removeIgnoredWord(word: string): void;
}

export default plugin;
