//Import Froala Editor
import "froala-editor/js/froala_editor.min";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/special_characters.min.js";
import "froala-editor/js/plugins/draggable.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/table.min.js";

// Import Froala Editor css files.
import "froala-editor/css/froala_editor.min.css";
import "froala-editor/css/plugins/special_characters.min.css";
import "froala-editor/css/plugins/draggable.min.css";
import "froala-editor/css/plugins/colors.min.css";
import "froala-editor/css/plugins/table.min.css";
// third party fonts css
import "../assets/fonts/lexend/style.css";
import "../assets/fonts/open-dyslexic/style.css";
import "../assets/fonts/adys/style.css";
// localization
import "froala-editor/js/languages/sv.js";
import "froala-editor/js/languages/da.js";
import "froala-editor/js/languages/nb.js";
import "froala-editor/js/languages/fi.js";
import "froala-editor/js/languages/nl.js";
import "froala-editor/js/languages/pt_pt.js";
import "froala-editor/js/languages/es.js";
import "froala-editor/js/languages/et.js";
import "froala-editor/js/languages/fr.js";
import "@/locales/froala/bg_bg.js";

import i18n from "../i18n";
import { sendMessage } from "@/plugins/messaging";

import {
  mdiMagnifyPlusOutline,
  mdiCloudCheck,
  mdiAlphaABox,
  mdiAccountVoice,
  mdiMicrophone,
  mdiPrinter
} from "@mdi/js";

import FroalaEditor from "froala-editor";
FroalaEditor.DefineIconTemplate(
  "material_design",
  '<svg viewBox="0 0 24 24" class="v-icon__svg"><path d="[MDI]" /></svg>'
);

import spellcheck from "./spellcheck";
spellcheck();

const originalHelpers = FroalaEditor.MODULES.helpers;

FroalaEditor.MODULES.helpers = function (...args) {
  const helpers = originalHelpers.apply(this, args);
  const screenSize = helpers.screenSize;
  helpers.screenSize = function () {
    if (window.innerWidth === 0 && window.outerWidth >= 768) {
      return FroalaEditor.LG;
    } else return screenSize();
  };

  return helpers;
};

FroalaEditor.DefineIcon("zoom", {
  MDI: mdiMagnifyPlusOutline,
  template: "material_design"
});
FroalaEditor.RegisterCommand("zoom", {
  focus: false,
  undo: false,
  type: "dropdown",
  options: {
    75: "75%",
    100: "100%",
    125: "125%",
    150: "150%"
  },
  callback: function (this: FroalaEditor.FroalaEditor, cmd, val) {
    const scale = parseInt(val) / 100;
    this.$wp[0].setAttribute(
      "style",
      `transform: scale(${scale}); transform-origin: top;`
    );
  }
});

FroalaEditor.DefineIcon("wordCounter", {
  MDI: mdiAlphaABox,
  template: "material_design"
});
FroalaEditor.RegisterCommand("wordCounter", {
  // TODO remove type casts in v9 since LocaleMessages are being deprecated
  title: String(i18n.t("word_count")),
  focus: false,
  undo: false,
  callback: function (this: FroalaEditor.FroalaEditor) {
    this.el.dispatchEvent(
      new CustomEvent("wordCounter", {
        detail: this
      })
    );
  }
});

FroalaEditor.DefineIcon("versionHistory", {
  MDI: mdiCloudCheck,
  template: "material_design"
});
FroalaEditor.RegisterCommand("versionHistory", {
  title: String(i18n.t("cloud_storage")),
  focus: false,
  undo: false,
  type: "dropdown",
  options: {
    revisions: i18n.t("revision_history")
  },
  callback: function (this: FroalaEditor.FroalaEditor) {
    this.el.dispatchEvent(
      new CustomEvent("versionHistory", {
        detail: this
      })
    );
  }
});

const bubbleEvent = function (
  this: FroalaEditor.FroalaEditor,
  cmd: string,
  val: string
) {
  const event = new CustomEvent(cmd, {
    bubbles: true,
    detail: val
  });

  this.el.dispatchEvent(event);
};

FroalaEditor.DefineIcon("speechSynthesis", {
  MDI: mdiAccountVoice,
  template: "material_design"
});

FroalaEditor.RegisterCommand("speechSynthesis", {
  title: String(i18n.t("text_to_speech")),
  focus: false,
  undo: false,
  showOnMobile: true,
  callback: function (this: FroalaEditor.FroalaEditor) {
    sendMessage("SYNTHESIZE_TEXT", { text: this.selection.text() });
  }
});

FroalaEditor.DefineIcon("speechRecognition", {
  MDI: mdiMicrophone,
  template: "material_design"
});

FroalaEditor.RegisterCommand("speechRecognition", {
  title: String(i18n.t("speech_to_text")),
  type: "dropdown",
  options: {
    speak: i18n.t("start_speech_recognition"),
    opts: i18n.t("settings")
  },
  undo: false,
  showOnMobile: true,
  callback: bubbleEvent
});

FroalaEditor.DefineIcon("print", {
  MDI: mdiPrinter,
  template: "material_design"
});

// Define print button.
FroalaEditor.RegisterCommand("print", {
  title: String(i18n.t("print")),
  undo: false,
  callback: bubbleEvent
});

export default FroalaEditor;

export const defaultConfig = () => ({
  key: process.env.VUE_APP_FROALA_KEY,
  toolbarSticky: false,
  spellcheck: false,
  toolbarBottom: false,
  attribution: false,
  tabSpaces: 4,
  disableRightClick: true,
  imagePaste: false,
  imageUpload: false,
  imageUploadRemoteUrls: false,
  imageOutputSize: true,
  imageEditButtons: ["imageRemove"],
  placeholderText: "",
  pluginsEnabled: [
    "colors",
    "table",
    "fontSize",
    "fontFamily",
    "paragraphFormat",
    "align",
    "specialCharacters",
    "draggable",
    "lists",
    "spellChecker"
  ],
  toolbarButtons: {
    moreText: {
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikeThrough",
        "subscript",
        "superscript",
        "fontFamily",
        "fontSize",
        "textColor",
        "clearFormatting"
      ],

      buttonsVisible: 2
    },
    moreParagraph: {
      buttons: [
        "paragraphFormat",
        "alignLeft",
        "alignCenter",
        "formatOLSimple",
        "alignRight",
        "alignJustify",
        "formatOL",
        "formatUL",

        "outdent",
        "indent"
      ],

      buttonsVisible: 1
    },
    moreRich: {
      buttons: ["insertTable", "specialCharacters", "insertHR"]
    },
    moreMisc: {
      buttons: ["versionHistory", "undo", "redo", "wordCounter", "selectAll"],
      align: "right",
      buttonsVisible: 4
    }
  },
  toolbarButtonsSM: {
    moreText: {
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikeThrough",
        "subscript",
        "superscript",
        "fontFamily",
        "fontSize",
        "textColor",
        "clearFormatting"
      ],

      buttonsVisible: 2
    },
    moreParagraph: {
      buttons: [
        "paragraphFormat",
        "alignLeft",
        "alignCenter",
        "formatOLSimple",
        "alignRight",
        "alignJustify",
        "formatOL",
        "formatUL",
        "outdent",
        "indent"
      ],

      buttonsVisible: 1
    },
    moreRich: {
      buttons: ["insertTable", "specialCharacters", "insertHR"],
      buttonsVisible: 0
    },
    moreMisc: {
      buttons: ["versionHistory", "undo", "redo", "wordCounter", "selectAll"],
      align: "right",
      buttonsVisible: 4
    }
  },
  toolbarButtonsXS: {
    moreText: {
      buttons: ["bold", "italic", "underline", "fontFamily", "clearFormatting"],
      buttonsVisible: 1
    },
    moreParagraph: {
      buttons: [
        "paragraphFormat",
        "alignLeft",
        "alignCenter",
        "formatOLSimple",
        "paragraphStyle"
      ],
      buttonsVisible: 0
    },
    moreRich: {
      buttons: ["insertTable", "specialCharacters", "insertHR"],
      buttonsVisible: 0
    },
    moreMisc: {
      buttons: ["versionHistory", "undo", "redo", "wordCounter"],
      align: "right",
      buttonsVisible: 1
    }
  },
  htmlAllowedTags: [
    "p",
    "s",
    "b",
    "strong",
    "i",
    "em",
    "u",
    "sub",
    "sup",
    "h1",
    "h2",
    "h3",
    "h4",
    "ul",
    "li",
    "ol",
    "div",
    "span",
    "hr",
    "br",
    "table",
    "th",
    "tr",
    "td",
    "thead",
    "tbody",
    "tfoot"
  ],
  tableEditButtons: [
    "tableHeader",
    "tableRows",
    "tableColumns",
    "|",
    "tableCells",
    "tableCellBackground",
    "tableCellVerticalAlign",
    "tableCellHorizontalAlign",
    "|",
    "tableRemove"
  ],
  fontFamily: {
    "Arial, Helvetica, sans-serif": "Arial",
    "efwzbhqqnrzlhejuhd, Arial": "Lexend",
    "gtkrilyzwplpbnpdkw, Arial": "Open Dyslexic",
    "ehnwbmelsqpsyfxjiz, Arial": "Adys"
  }
});
