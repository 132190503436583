
import { defineComponent, reactive, toRefs } from "vue";

import { languageText } from "@/i18n";

import { logger } from "@/helpers";

import { mdiMicrophone } from "@mdi/js";
const icons = {
  mdiMicrophone
};
const languages = [
  "en-US",
  "sv-SE",
  "nb-NO",
  "bg-BG",
  "da-DK",
  "fi-FI",
  "nl-NL",
  "de-DE",
  "pt-PT",
  "es-ES",
  "fr-FR"
].map(value => {
  const languageCode = value.split(/[-_]/)[0];
  return { value, text: languageText({ languageCode }) };
});

export default defineComponent({
  name: "speech-recognition",

  props: {
    language: { type: String, required: true }
  },

  setup(props) {
    const data = reactive({
      showDialog: false,

      recognizing: false,
      recognition: null as any,

      selectedLanguage: "en-US"
    });

    function showSettings() {
      data.showDialog = true;
    }

    function recognitionInit(element: Element) {
      const SpeechRecog =
        window.SpeechRecognition || window.webkitSpeechRecognition;

      data.recognition = new SpeechRecog();
      data.recognition.continuous = true;
      data.recognition.interimResults = true;

      data.recognition.onstart = () => {
        logger.log("recognition begun", data.recognition.lang);
        data.recognizing = true;
      };

      const nodeCleanup = () => {
        const node = element.querySelector(".speech-to-text");
        if (node) {
          node.removeAttribute("class");
          const final = node.querySelector(".final");
          if (final) {
            node.innerHTML = final.innerHTML;
          }
        }
      };

      data.recognition.onend = () => {
        logger.log("recognition stopped");
        data.recognizing = false;
        nodeCleanup();
      };

      data.recognition.onerror = (err: any) => {
        logger.warn("recognition error:", err);
        data.recognizing = false;
        nodeCleanup();
      };

      data.recognition.onresult = (event: any) => {
        const span = element.querySelector(".speech-to-text");
        if (!span) {
          recognitionStop();
          return;
        }

        let final = span.querySelector(".final");
        let interim: HTMLSpanElement;

        if (!final) {
          final = document.createElement("span");
          final.setAttribute("class", "final");

          interim = document.createElement("span");
          interim.setAttribute("class", "interim");

          span.appendChild(final);
          span.appendChild(interim);
        } else {
          interim = span.querySelector(".interim") as HTMLSpanElement;
        }

        interim.innerText = "";

        // eslint-disable-next-line
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            final.innerHTML += event.results[i][0].transcript.replace(
              /\n+/,
              "<br>"
            );
          } else {
            interim.innerText += event.results[i][0].transcript;
          }
        }
      };
    }

    function recognitionStart(editor: any) {
      if (data.recognizing) {
        recognitionStop();
      }

      if (data.recognition === null) {
        recognitionInit(editor.el);
      }

      editor.format.apply("span", { class: "speech-to-text" });
      data.recognition.lang = data.selectedLanguage;
      data.recognition.start();
    }
    function recognitionStop() {
      if (data.recognition !== null) {
        data.recognition.stop();
      }
    }

    if (languages.map(item => item.value).includes(props.language)) {
      // eslint-disable-next-line vue/no-setup-props-destructure
      data.selectedLanguage = props.language;
    }

    return {
      icons,
      languages,
      showSettings,
      recognitionStart,
      recognitionStop,
      ...toRefs(data)
    };
  }
});
